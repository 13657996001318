var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "packageListWrapper",
    staticClass: "package-list-wrapper"
  }, [_c('div', {
    class: ['d-grid gap-30 store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3 package-list', {
      'fade-out-list': _vm.listFade
    }]
  }, [_vm.list && _vm.list.length > 0 ? _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: `${_vm.listType}-list-package-item-${item.id}`
    }, [_c('nuxt-link', {
      staticClass: "sim-item-link",
      attrs: {
        "data-testid": "sim-package-item",
        "to": _vm.localePath({
          name: 'index-list-package',
          params: {
            list: _vm.selectedCountry ? _vm.selectedCountry.slug : '',
            package: item ? item.slug : ''
          }
        })
      }
    }, [_c('SimItem', {
      attrs: {
        "item": item,
        "list-type": _vm.listType,
        "country": _vm.selectedCountry,
        "from": "homepage"
      },
      on: {
        "onOpenSupportedCountries": _vm.onOpenSupportedCountries
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.selectItemEvent(item);
        }
      }
    })], 1)], 1);
  }) : _vm._e()], 2), _vm._v(" "), _vm.$route.params.package ? _c('div', {
    staticClass: "package-list-detail"
  }, [_c('nuxt-child', {
    attrs: {
      "list-type": _vm.listType,
      "selected-country": _vm.selectedCountry
    },
    on: {
      "onPackageDetailMounted": _vm.onPackageDetailMounted
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('client-only', [_c('supported-countries-modal', {
    attrs: {
      "modal-view": _vm.modals.supportedCountries.model,
      "countries": _vm.modals.supportedCountries.countries,
      "title": _vm.$t('components.supported.countries.modal.available-countries-title'),
      "search-input-placeholder": _vm.$t('placeholder.supported.countries.modal.available-countries-search'),
      "empty-text": _vm.$t('global.no-country-available')
    },
    on: {
      "onCloseSupportedCountriesModal": _vm.onCloseSupportedCountries
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
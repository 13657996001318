<template>
  <div class="package-list-wrapper" ref="packageListWrapper">
    <div
      :class="[
        'd-grid gap-30 store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3 package-list',
        {
          'fade-out-list': listFade,
        },
      ]"
    >
      <template v-if="list && list.length > 0">
        <div v-for="(item, index) in list" :key="`${listType}-list-package-item-${item.id}`">
          <nuxt-link
            class="sim-item-link"
            data-testid="sim-package-item"
            :to="
              localePath({
                name: 'index-list-package',
                params: {
                  list: selectedCountry ? selectedCountry.slug : '',
                  package: item ? item.slug : '',
                },
              })
            "
          >
            <SimItem
              :item="item"
              :list-type="listType"
              :country="selectedCountry"
              from="homepage"
              @click.native="selectItemEvent(item)"
              @onOpenSupportedCountries="onOpenSupportedCountries"
            ></SimItem>
          </nuxt-link>
        </div>
      </template>
    </div>
    <div class="package-list-detail" v-if="$route.params.package">
      <nuxt-child
        :list-type="listType"
        :selected-country="selectedCountry"
        @onPackageDetailMounted="onPackageDetailMounted"
      />
    </div>
    <client-only>
      <supported-countries-modal
        :modal-view="modals.supportedCountries.model"
        :countries="modals.supportedCountries.countries"
        :title="$t('components.supported.countries.modal.available-countries-title')"
        :search-input-placeholder="$t('placeholder.supported.countries.modal.available-countries-search')"
        :empty-text="$t('global.no-country-available')"
        @onCloseSupportedCountriesModal="onCloseSupportedCountries"
      />
    </client-only>
  </div>
</template>

<script>
  import SimItem from '~/components/sim/item.vue'
  import SupportedCountriesModal from '~/components/modals/supported-countries-modal'
  import pkgMxn from '~/mixins/package.js'

  export default {
    name: 'packages-list',
    components: {
      SimItem,
      SupportedCountriesModal,
    },

    mixins: [pkgMxn(false, undefined)],

    props: {
      listType: {
        type: String,
        required: true,
      },
      list: {
        type: Array,
        required: false,
        default: [],
      },
      listCount: {
        type: Number,
        required: false,
        default: 0,
      },
      listFade: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectedCountry: {
        type: Object,
        required: false,
        default: null,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectedDataType: {
        type: String,
        required: false,
        default: 'others',
      },
    },

    data() {
      return {
        modals: {
          supportedCountries: {
            model: false,
            countries: [],
          },
        },
      }
    },

    head() {
      const selectedCountry = this.selectedCountry

      if (selectedCountry && selectedCountry.packages) {
        const metaInfos = this.buildInitialMetaInfos()

        if (this.listType === 'local-packages' || this.listType === 'regional-packages') {
          this.transformMetaInfos(metaInfos, selectedCountry)

          return {
            title: metaInfos.title,
            meta: [
              {
                hid: 'description',
                name: 'description',
                content: metaInfos.description,
              },
              {
                hid: 'keywords',
                name: 'keywords',
                content: metaInfos.keywords,
              },
            ],
          }
        }
      }
    },

    watch: {
      list() {
        this.sendEvent()
      },
    },

    mounted() {
      this.sendEvent()
    },

    methods: {
      selectItemEvent(item) {
        return this.$utils.sendEvent('selectItem', {
          listType: this.listType,
          item,
        })
      },
      sendEvent() {
        if (!this.$route.params.package) {
          this.$utils.sendEvent('viewItemList', {
            packages: this.list,
            dataType: this.selectedDataType,
            listType: this.listType,
          })
        }
      },

      buildInitialMetaInfos() {
        if (this.listType === 'local-packages') {
          return {
            title: this.$t('seo.country.title.v2'),
            description: this.$t('seo.country.description'),
            keywords: this.$t('seo.country.keywords'),
          }
        } else if (this.listType === 'regional-packages') {
          return {
            title: this.$t('seo.region.title'),
            description: this.$t('seo.region.description'),
            keywords: this.$t('seo.region.keywords'),
          }
        } else {
          return {
            title: '',
            description: '',
            keywords: '',
          }
        }
      },

      countryPackagesMinimumPrice(countryPackages) {
        const minimumMinorAmount = Math.min(
          ...countryPackages.map((cp) => this.pkgMxn_packageCurrentPrice(cp).minor_amount)
        )
        const minimumPricePackage = countryPackages.find(
          (cp) => this.pkgMxn_packageCurrentPrice(cp).minor_amount === minimumMinorAmount
        )

        return this.pkgMxn_packageCurrentPrice(minimumPricePackage).formatted
      },

      transformMetaInfos(metaInfos, selectedCountry) {
        const matchKeys = ['country', 'region', 'amount']
        // START-NOSCAN
        const metaMatchRegex = new RegExp(/\{(.+?)\}/, 'g')
        // END-NOSCAN
        const metaInfosKeysArray = Object.keys(metaInfos)
        const minPrice = this.countryPackagesMinimumPrice(selectedCountry.packages)

        metaInfosKeysArray.forEach((v) => {
          let val = metaInfos[v].replace(metaMatchRegex, (_m, body, _index) => {
            if (matchKeys.includes(body)) {
              if (body === 'country' || body === 'region') return selectedCountry.title
              if (body === 'amount') return minPrice
            }
            return body
          })

          metaInfos[v] = val
        })
      },

      onOpenSupportedCountries(countries) {
        this.modals.supportedCountries.countries = countries
        this.modals.supportedCountries.model = true
      },
      onCloseSupportedCountries() {
        this.modals.supportedCountries.model = false
      },
      onPackageDetailMounted(payload) {
        this.$emit('onPackageDetailMounted', payload)
      },
    },

    scrollToTop: false,
  }
</script>

<style lang="scss" scoped>
  .package-list-wrapper {
    &.page-leave-active {
      display: none;
    }

    .sim-item-link {
      text-decoration: none;
    }
  }

  .package-list {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .fade-out-list {
    opacity: 0;
  }
</style>
